import React from "react"

import footerImg from "../../assets/buy/footer.png"
import footerBg from "../../assets/buy/footer-background.png"
import logo from "../../assets/logo-horizontal.png"

import fb from "../../assets/buy/fb.png"
import ig from "../../assets/buy/ig.png"
import twitter from "../../assets/buy/twitter.png"
import email from "../../assets/buy/email.png"

import MailchimpSubscribe from "react-mailchimp-subscribe"
 
const url = "https://differenceengine.us19.list-manage.com/subscribe/post?u=3b66468988aeaf0a76b8e7349&amp;id=f76b382435";

// simplest form (only email)
const SimpleForm = () => <MailchimpSubscribe url={url}/>

const FinalFooter = () => {
    return (
        <div className="final-footer">
            <img src={footerImg} alt="subscribe" style={{
                backgroundImage: "url(" + footerBg + ")",
                backgroundSize: "100vw 30vh",
                backgroundRepeat: "no-repeat",
                }}/>
            <div className="img-text">
                <h6 style={{marginBottom: '0'}}>
                For more news and updates about comics in
                Southeast Asia, sign up for our e-newsletter.
                </h6>
                <SimpleForm/>
                <p>
                Follow Difference Engine on Facebook and Instagram at
                @differenceenginesg.
                </p>
            </div>
            <div className="text-left" id="social-media">
                <a href="https://www.facebook.com/differenceenginesg"><img src={fb}/></a>
                <a href="https://www.instagram.com/differenceenginesg"><img src={ig}/></a>
                <a href="https://twitter.com/DEcomics_Sg"><img src={twitter}/></a>
                <a href="mailto:readcomics@differenceengine.sg"><img src={email}/></a>
            </div>

            <nav className="navbar">
                    <div className="navbar-brand" id="copyright">
                    © 2020 by Difference Engine. All Rights Reserved
                    </div>  
                    <a className="logo" href="#">
                        <img src={logo} alt="logo"/>
                    </a>
                    <div className="navbar-brand" id="terms-conditions">
                    <a href="http://differenceengine.sg/privacy" style={{
                        color: 'rgb(255, 65, 185)'
                    }}>Privacy Policy</a>
                    </div>
            </nav>

        </div>
    );
}

export default FinalFooter

/*<nav className="navbar">
                    <div className="navbar-brand" id="copyright">
                    © 2020 by Difference Engine. All Rights Reserved
                    </div>  
                    <a className="logo" href="#">
                        <img src={logo} alt="logo"/>
                    </a>
                    <div className="navbar-brand" id="terms-conditions">
                    <a href="https://differenceengine.sg/terms-conditions/" style={{
                        color: 'rgb(255, 65, 185)'
                    }}>Terms & Conditions/PDPA</a>
                    </div>
                </nav>*/


/*<ul style={{
                listStyleType: 'none',
                margin: '0',
                padding: '0',
                position: 'absolute',
            }}>
                <li style={{float: 'left', marginBottom: '0'}}>
                © 2020 by Difference Engine. All Rights Reserved
                </li>
                <li style={{
                    float: 'none',
                    marginBottom: '0',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}>
                    <img src={logo} alt="logo" style={{
                        objectFit: 'contain',
                    }}/>
                </li>
                <li>

                </li>
            </ul>*/